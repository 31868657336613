<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('REWARD_VALUES.DAILY_LOGIN')}}</h1>
    <select v-model="rewardIndex">
      <option v-for="index in constants.DROPDOWN_OPTIONS" v-bind:key="index.value" :value="index.value">{{ index.text }}</option>
    </select>
    <div class="col">
      <div class="action-buttons-table" v-if="dailyReward[rewardIndex].length > 0">
        <div class="row header-row">
          <p>{{ $t(`REWARD_VALUES.REWARD_TYPE`) }}</p>
          <p>{{ $t('REWARD_VALUES.RARITY') }}</p>
          <p>{{ $t('REWARD_VALUES.CHEST_ID') }}</p>
          <p>{{ $t('REWARD_VALUES.AMOUNT') }}</p>
          <p>{{ $t('ACTIONS') }}</p>
        </div>
        <div v-for="(reward, index) in dailyReward[rewardIndex]" v-bind:key="index" class="row">
          <select v-model.number="dailyReward[rewardIndex][index].type" @change="initializeRewardType(index, dailyReward[rewardIndex][index].type)">
            <option v-for="option in constants.REWARD_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('REWARD_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="dailyReward[rewardIndex][index].rarity" :disabled="![3,4].includes(dailyReward[rewardIndex][index].type)">
            <option v-for="option in constants.CARD_RARITY" :value="option.value" v-bind:key="option.value">{{ $t('REWARD_VALUES.' + option.text) }}</option>
          </select>
          <select v-model="dailyReward[rewardIndex][index].chest_id" :disabled="dailyReward[rewardIndex][index].type !== 2">
            <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <input type="number" v-model.number="dailyReward[rewardIndex][index].amount" />

          <button @click="dailyReward[rewardIndex].splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
        </div>
      </div>
      <button @click="addReward" class="success">{{ $t('REWARD_VALUES.ADD_REWARD') }}</button>
    </div>
    <div class="buttons-section">
      <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
      <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
      <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
      <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch, ref, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "DailyLoginReward",
  setup() {

    const constants = {
      DROPDOWN_OPTIONS: [...Array(30).keys()].map((_, index) => {
        return {
          text: `DAY ${index + 1}`,
          value: `${index + 1}`
        }
      }),
      REWARD_TYPES: [
        {
          text: "GOLD",
          value: 0
        },
        {
          text: "GEM",
          value: 1
        },
        {
          text: "CHEST",
          value: 2
        },
        {
          text: "UNIT",
          value: 3
        },
        {
          text: "HERO",
          value: 4
        }
      ],
      CARD_RARITY: [
        {
          text: "COMMON",
          value: 0
        },
        {
          text: "RARE",
          value: 1
        },
        {
          text: "EPIC",
          value: 2
        },
        {
          text: "LEGENDARY",
          value: 3
        }
      ]
    }

    const rewardIndex = ref('1');
    const store = useStore();
    const usedPages = ["rewards"];
    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
            store.dispatch(dispatchStr);
          })
      });
      }
    );
    const rewards = computed(() => store.getters['rewards/getRewards'])
    const units = computed(() => store.getters['units/getUnits'])
    const heroes = computed(() => store.getters['heroes/getHeroes'])
    const chests = computed(() => store.getters['chests/getChests'])
    const dailyReward = reactive({
      S: [],
      M: [],
      L: []
    })

    onMounted(() => {
      if (rewards.value.length > 0) {
        const editingReward = JSON.parse(JSON.stringify(rewards.value.find(x => x.id === 'daily-login')))
        Object.keys(editingReward).forEach(key => {
          dailyReward[key] = editingReward[key]
        })
      }
      store.dispatch("loader/loadingStatus", false)

    })

    watch(() => store.getters['rewards/getRewards'], rewards => {
      if(rewards.length > 0) {
        const editingReward = JSON.parse(JSON.stringify(rewards.find(x => x.id === 'daily-login')))
        Object.keys(editingReward).forEach(key => {
          dailyReward[key] = editingReward[key]
        })
      }
    })
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));



    const submit = () => {
      store.dispatch('rewards/updateReward', {id: 'daily-login', data: dailyReward})
    }

    const exportTo = environment => {
      store.dispatch('rewards/exportReward', {id: 'daily-login', data: dailyReward, environment })
    }

    const initializeRewardType = (index, type) => {
      const reward = {
        type,
        amount: 0
      }
      if([3,4].includes(type))
        reward.rarity = 0
      if(type === 2)
        reward.chest_id = ""
      dailyReward[rewardIndex.value][index] = reward
    }

    const addReward = () => {
      dailyReward[rewardIndex.value].push({ type: 0, amount: 0})
    }


    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      dailyReward,
      submit,
      exportTo,
      env,
      units,
      heroes,
      chests,
      constants,
      rewardIndex,
      initializeRewardType,
      addReward
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}

.row.condition-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;

}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-info {
  color: gray;
  font-size: 0.75rem;
  text-align: start;
}
</style>